<template>
  <div>
    <section class="vh-100">
      <div class="container mt-5 h-90">
        <b-row class="text-center mt-4 mb-4">
          <b-col>
            <h1 style="font-size: 50px; font-weight: 900; color: white">
              <span>Reset Password</span>
            </h1>
          </b-col>
        </b-row>
      </div>

      <div class="container h-50">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-6">
            <div class="card" style="border-radius: 1rem">
              <b-row class="text-center mt-5">
                <b-col>
                  <img
                    src="../assets/images/webpatrol.png"
                    alt="Web Patrol"
                    style="height: 13vh"
                  />
                </b-col>
              </b-row>

              <div class="card-body p-5 text-left">
                <div v-if="change_password == false">
                  <div class="form-outline mb-4">
                    <label class="form-label" for="typeEmailX-2"
                      >Enter Your Register Email</label
                    >
                    <input
                      v-model="input.email"
                      type="email"
                      id="typeEmailX-2"
                      class="form-control form-control-lg"
                      placeholder="e.g info@example.com"
                    />
                  </div>

                  <button
                    class="btn btn-lg btn-block"
                    type="submit"
                    style="background-color: blue;"
                    @click="sendMail()"
                  >
                    Send Mail
                  </button>
                </div>
                <div v-if="change_password == true">
                  <div class="form-outline mb-4">
                    <label class="form-label" for="typeEmailX-2"
                      >Enter new password</label
                    >
                    <input
                      v-model="input.password"
                      type="password"
                      id="typeEmailX-2"
                      class="form-control form-control-lg"
                      placeholder="new password"
                    />
                  </div>

                  <button
                    class="btn btn-lg btn-block"
                    type="submit"
                    style="background-color: blue;"
                    @click="changePassword()"
                  >
                    Update Password
                  </button>
                  <div>
                    <p>we will send password reset link to your register mail id.</p>
                  </div>
                </div>
                <hr class="my-4" />

                <div class="form-outline mb-4 text-center">
                  <router-link
                    style="color: blue"
                    tag="a"
                    to="/auth/login"
                    ><h6>Login Now</h6></router-link
                  >
                </div>
                <div class="form-outline mb-4 text-center">
                  <router-link
                    style="color: blue"
                    tag="a"
                    to="/auth/register"
                    ><h6>Join now, create your FREE account</h6></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserController from "../../services/UserService";
import Userauth from "../auth/js/index";
export default {
  name: "forgotpassword",
  data() {
    return {
      input: { email: "", password: "", token: "" },
      auth: "",
      change_password: false,
    };
  },
  mounted() {
    if (this.$route.query.tkn) {
      this.change_password = true;
      this.input.token = this.$route.query.tkn;
    }
  },
  methods: {
    async sendMail() {
      let payload = { email: this.input.email };
      let response = await UserController.sendForgotPasswordMail(payload);
      if (response.result) {
        this.$toasted.show(
          "please check your mail and update your password from click on reset link",
          {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 5000,
          }
        );
      } else {
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 3000,
        });
      }
    },
    async changePassword() {
      if (this.validate()) {
        var payload = {
          password: this.input.password,
          token: this.input.token,
        };
        let response = await UserController.changePassword(payload);
        if (response.result) {
          this.$toasted.show("Successfully Password changed", {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 3000,
          });
          this.$router.replace("/auth/login");
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 3000,
          });
        }
      }
      console.log(response);
    },
    async Auth() {
      let response = await UserController.authenticate();
      if (response != true) {
        clearInterval(this.auth);
        Userauth.Logout();
      }
    },
    validate() {
      if (this.input.password == "" && this.input.password.length < 6) {
        this.$toasted.show("password length should be more then 6 character", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100%;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 15px 10px #000000 !important;
}
button {
  background-color: #f7981e;
  color: #fefeff;
}
</style>